<template>
  <div>
    <public-header />
    <div class="wrapper">
      <div class="page-header page-header-small">
        <div
          class="page-header-image"
          data-parallax="true"
        />
        <div class="content-center mt-4">
          <div class="container">
            <h1 class="title">
              Make an Impact with Text Message Marketing & Sales Solutions
            </h1>
            <div class="text-center white-text">
              <h4>Text message marketing and sales solutions drive astonishing lead flow and extraordinary ROI.</h4>
            </div>
            <div class="getStartedButtonWrapper">
              <button
                class="getStarted"
                @click="$router.push('/register')"
              >
                Get Started!
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="content-center mt-4">
          <div class="container">
            <h1 class="title">
              The Team
            </h1>
          </div>
        </div> -->
      </div>
      <div class="">
        <section class="marketing-sales">
          <div class="row">
            <div class="col-12">
              <h2 class="letsconnectTeam mb-0 fw-400">
                How Can Text Marketing Help Your Business?
              </h2>
            </div>
            <div class="col-md-7 mx-auto">
              <div class="teamMember">
                <img
                  src="https://www.gravatar.com/avatar/1048fdde70396fdc554440e9d4f3ef02?s=200&d=mp"
                  alt="alt"
                >
              </div>
            </div>
            <div class="col-md-4 m-0 mx-auto">
              <div class="teamMember">
                <template>
                  <v-expansion-panels flat>
                    <v-expansion-panel
                      v-for="(item,i) in accordContent"
                      :key="i"
                    >
                      <v-expansion-panel-header>
                        <h5 class="text-left fw-600">
                          {{ item.label }}
                        </h5>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <p class="text-left">
                          {{ item.text }}
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </div>
            </div>
          </div>
        </section>
        <v-row>
          <v-tabs v-model="tabs">
            <v-tab
              v-for="item in tabItems"
              :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabs">
            <v-tab-item> Content 1 </v-tab-item>
            <v-tab-item> Content 2 </v-tab-item>
            <v-tab-item :transition="false">
              Content 3
              <v-btn @click="tabs = 0">
                Link to Tab 1
              </v-btn>
            </v-tab-item>
          </v-tabs-items>
        </v-row>
        <section class="text-marketing">
          <div class="row py-4 p-5 justify-md-center justify-content-center">
            <div class="col-md-12 text-center">
              <h2>Text Marketing & Sales, by the Numbers</h2>
            </div>
            <div class="col-md-6 col-lg-4 p-3 col-xs-12 col text-center">
              <img
                src="https://picsum.photos/200/300?random=1"
                class="round-thumb rounded-circle"
              >
              <h3 class="p-2 fw-700 mb-0">
                Generate New Business
              </h3>
              <p class="line-height-18">
                50% of consumers make a purchase after receiving text message marketing
              </p>
            </div>
            <div class="col-md-6 col-lg-4 p-3 col-xs-12 col text-center">
              <img
                src="https://picsum.photos/200/300?random=1"
                class="round-thumb rounded-circle"
              >
              <h3 class="p-2 fw-700 mb-0">
                Increase Coupon Redemption
              </h3>
              <p class="line-height-18">
                77% opted in to business text messaging to receive coupons or deals2
              </p>
            </div>
            <div class="col-md-6 col-lg-4 p-3 col-xs-12 col text-center">
              <img
                src="https://picsum.photos/200/300?random=1"
                class="round-thumb rounded-circle"
              >
              <h3 class="p-2 fw-700 mb-0">
                Amplify Traffic, In Store & Online
              </h3>
              <p class="line-height-18">
                52.6% of all worldwide online traffic was generated through mobile phones3
              </p>
            </div>
          </div>
        </section>
        <section class="highlight-industries py-5">
          <div class="row p-3">
            <div class="col-md-12 text-center">
              <h2 class="text-white mb-0">
                Highlighted Industries
              </h2>
              <p class="text-white">
                Redefine success with text messaging solutions for every business category.
              </p>
            </div>
            <div class="col-md-4 p-3">
              <div class="bg-white hi-light-box d-flex align-items-start">
                <img
                  src="https://picsum.photos/200/300?random=1"
                  class="round-thumb rounded-circle p-3"
                >
                <div class="p-3">
                  <h5 class="mb-0 fw-700 fs-18">
                    Retail & Ecommerce
                  </h5>
                  <p>Ring up more sales when you employ high-powered, results-driven text marketing strategies.</p>
                  <a href="#">Learn More</a>
                </div>
              </div>
            </div>
            <div class="col-md-4 p-3">
              <div class="bg-white hi-light-box d-flex align-items-start">
                <img
                  src="https://picsum.photos/200/300?random=1"
                  class="round-thumb rounded-circle p-3"
                >
                <div class="p-3">
                  <h5 class="mb-0 fw-700 fs-18">
                    Marketing Agencies
                  </h5>
                  <p>See stellar ROI for you and your clients by adding SMS marketing to your services portfolio.</p>
                  <a href="#">Learn More</a>
                </div>
              </div>
            </div>
            <div class="col-md-4 p-3">
              <div class="bg-white hi-light-box d-flex align-items-start">
                <img
                  src="https://picsum.photos/200/300?random=1"
                  class="round-thumb rounded-circle p-3"
                >
                <div class="p-3">
                  <h5 class="mb-0 fw-700 fs-18">
                    Property Management
                  </h5>
                  <p>Generate qualified leads and streamline leasing with text marketing.</p>
                  <a href="#">Learn More</a>
                </div>
              </div>
            </div>
            <div class="col-md-12 text-center">
              <button class="btn-hi-light round-btn">
                See All Industries
              </button>
            </div>
          </div>
        </section>
        <section class="message-solutions row py-5 p-3 ">
          <div class="container">
            <v-row dense>
              <v-col
                cols="12"
                class="text-center "
              >
                <h2 class="text-black">
                  Learn More About Text Message Marketing & Sales Solutions
                </h2>
              </v-col>
            </v-row>

            <v-row class="justify-content-center">
              <!--/*Box 1*/-->
              <v-col
                cols="6"
                md="12"
                lg="6"
                class="border-md-right xs"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    lg="12"
                    class="border-md-right xs"
                  >
                    <v-row
                      class="align-items-center"
                      justify="center"
                    >
                      <v-col
                        cols="6"
                        md="6"
                        lg="12"
                        class="border-md-right xs"
                      >
                        <img
                          src="https://picsum.photos/500/300?random=1"
                          class="rounded"
                        >
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                        lg="12"
                        class="border-md-right xs"
                      >
                        <a
                          href="#"
                          class="fw-400 m-0 p-1"
                        >Make the Most of Mobile Commerce by Adding Text Messaging</a>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    lg="12"
                    class="border-md-right xs"
                  >
                    <v-row
                      class="align-items-center"
                      justify="center"
                    >
                      <v-col
                        cols="12"
                        md="12"
                        lg="12"
                        class="border-md-right flex-column d-flex xs"
                      >
                        <a
                          href="#"
                          class="fw-400 mb-2 p-1"
                        >4 Ways Text Marketing Can Help Property Managers Sell More</a>
                        <a
                          href="#"
                          class="fw-400 m-0 p-1"
                        >4 Types of Mobile Coupons to Use in Your SMS Marketing Campaigns</a>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="6"
                md="12"
                lg="6"
                class="border-md-right xs"
              >
                <v-row>
                  <!-- // Box 2 // -->
                  <v-col
                    cols="12"
                    md="12"
                    lg="12"
                    class="border-md-right xs"
                  >
                    <v-row
                      class="align-items-center"
                      justify="center"
                    >
                      <v-col
                        cols="6"
                        md="6"
                        lg="6"
                        class="border-md-right xs"
                      >
                        <img
                          src="https://picsum.photos/500/300?random=1"
                          class="rounded"
                        >
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                        lg="6"
                        class="border-md-right xs"
                      >
                        <a
                          href="#"
                          class="fw-400 m-0 p-1"
                        >
                          How Text Can Increase Retail Engagement & Loyalty From Existing Customers
                        </a>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    lg="12"
                    class="border-md-right xs"
                  >
                    <v-row
                      class="align-items-center"
                      justify="center"
                    >
                      <v-col
                        cols="6"
                        md="6"
                        lg="6"
                        class="border-md-right xs"
                      >
                        <img
                          src="https://picsum.photos/500/300?random=1"
                          class="rounded"
                        >
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                        lg="6"
                        class="border-md-right xs"
                      >
                        <a
                          href="#"
                          class="fw-400 m-0 p-1"
                        >
                          Text Marketing for Mother’s Day Promotions – What Your Business Needs to Know
                        </a>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    lg="12"
                    class="border-md-right xs"
                  >
                    <v-row
                      class="align-items-center"
                      justify="center"
                    >
                      <v-col
                        cols="6"
                        md="6"
                        lg="6"
                        class="border-md-right xs"
                      >
                        <img
                          src="https://picsum.photos/500/300?random=1"
                          class="rounded"
                        >
                      </v-col>
                      <v-col
                        cols="6"
                        md="6"
                        lg="6"
                        class="border-md-right xs"
                      >
                        <a
                          href="#"
                          class="fw-400 m-0 p-1"
                        >3 Ways SMS Helps Auto Dealerships Connect with Leads That Aren’t Responding</a>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </section>
        <section class="faq-section">
          <div class="container">
            <h2 class="text-center">
              Frequently Asked Questions
            </h2>
            <div class="text-left bg-transparent">
              <template>
                <v-expansion-panels flat>
                  <v-expansion-panel
                    v-for="(item,i) in faqContent"
                    :key="i"
                  >
                    <v-expansion-panel-header>
                      <h5 class="text-left text-black fw-700">
                        {{ item.label }}
                      </h5>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p v-if="item.text">
                        {{ item.text }}
                      </p>
                      <div v-if="i==0">
                        <ul>
                          <li
                            v-for="(x,i) in faqContent[0].arrText"
                            :key="i"
                          >
                            {{ x }}
                          </li>
                        </ul>
                        <p v-if="faqContent[0].footerText">
                          {{ faqContent[0].footerText }}
                        </p>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </div>
          </div>
        </section>
      </div>
    </div>
    <public-footer />
  </div>
</template>

<script>
  const accordText = [{
                        label: 'Promote Sales & Drive Traffic',
                        text: 'Discover text message marketing solutions like mobile coupons and MMS campaigns that boost sales and generate both online and in-store traffic.',
                      },
                      {
                        label: 'Grow Your Customer Base',
                        text: 'Leverage lead generation solutions like text-to-join Keywords, QR codes, and Sign-Up Forms. Create a compelling incentive to join and watch your SMS marketing list multiply.',
                      },
                      {
                        label: 'Engage Your Community',
                        text: 'Stay connected and top of mind with SMS campaigns that share helpful tips and inspirational messages. Use automated text messaging solutions to schedule out timely or seasonal messaging.',
                      },
                      {
                        label: 'Build Brand Awareness',
                        text: 'Amplify the impact of multi-channel campaigns with text message marketing. Take advantage of custom app integrations with software platforms like HubSpot, Squarespace, and Constant Contact to create seamless workflows.',
                      },
                      {
                        label: 'Promote Sales & Drive Traffic',
                        text: 'Increase event registrations and attendance with text invitations and automated reminders via Drip Campaigns. Text alerts can enhance the attendee experience and are great for critical follow ups.',
                      },
                      {
                        label: 'Nurture & Convert Leads',
                        text: 'Text 1:1 with prospects and re-engage with those that have stopped responding to traditional outlets such as phone calls and emails. Automated SMS marketing solutions allow for set-it-and-forget-it lead nurture.',
                      },
  ]

  const accordText2 = [{
                         label: 'How do you market a business through text?',
                         text: 'Text Marketing can grow your business with powerful and engaging solutions, including:',
                         arrText: ['Instant Delivery of Mobile Coupons & Performance', 'Engaging Mobile Loyalty Programs', 'Transformative Customer Engagement via 2-Way Messaging'],
                         footerText: 'Text offers multiple marketing solutions for any business, including yours! Visit our SMS marketing overview to dig deeper into how and why text marketing is so powerful.',
                       },
                       {
                         label: 'Does SMS marketing really work?',
                         text: 'Absolutely. What other channel offers a 98% read rate with most messages being read within minutes (or seconds) of receipt? People love to text and we’ve seen truly impactful numbers for our customers, including CTRs at 40% to 4-figure ROI for some customers. When you want your message seen - send it in a text.',
                       },
                       {
                         label: 'When should I use SMS marketing?',
                         text: 'SMS marketing fits comfortably into every stage of the buyer’s journey. Use text marketing at the top of the funnel to grow a mobile marketing list and drive interest in your business with the promise of meaningful discounts and exclusives. Continue to nurture your customers through the use of two-way texting and text-based loyalty programs, and follow up after purchases with a thank you message, text receipt, survey, or review request. And don’t forget to use text as a low-friction nudge to help get lapsed or quiet customers engaging with your brand again.',
                       },
                       {
                         label: 'How do I create a QR code for text?',
                         text: 'EZ Texting offers a built-in QR code generator that you can easily update so your QR codes are associated with your predetermined automated text message replies whenever anyone interacts with it. QR codes are exploding in popularity, so don’t be shy about using them.',
                       },
                       {
                         label: 'Promote Sales & Drive Traffic',
                         text: 'Increase event registrations and attendance with text invitations and automated reminders via Drip Campaigns. Text alerts can enhance the attendee experience and are great for critical follow ups.',
                       },
                       {
                         label: 'Nurture & Convert Leads',
                         text: 'Text 1:1 with prospects and re-engage with those that have stopped responding to traditional outlets such as phone calls and emails. Automated SMS marketing solutions allow for set-it-and-forget-it lead nurture.',
                       },
  ]
  export default {
    name: 'MarketingSales',

    components: {
      PublicHeader: () => import('../components/PublicHeader'),
      PublicFooter: () => import('../components/PublicFooter'),
    },

    data: () => ({
      isUserLoggedIn: false,
      accordContent: accordText,
      faqContent: accordText2,
    }),
    computed: {},
    created () {
      window.scrollTo(0, 0)
      this.checkIfUserLoggedIn()
    },
    methods: {
      checkIfUserLoggedIn () {
        const username = localStorage.getItem('username')
        if (username && username.length) {
          this.isUserLoggedIn = true
        }
      },
      redirectToPage (path) {
        window.location = window.location.origin + path
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css";
@import '../../../assets/bootstrap.min.css';
@import '../../../assets/now-ui-kit.css';
.page-header-image {
  // background-image: radial-gradient(circle 1693px at 100% 100%,#47b3f9 0,#3fd7ff 23%,#00e3ff 41%,#1ac6fb 62%,#b54fff 100%);
  background-color: #f5f5f5;
}
.page-header:before {
  background-color: rgba(8, 8, 8, 0);
}
.page-header.page-header-small {
  max-height: unset!important;
  // height: 600px!important;
  position: relative;
}
.page-header .title{
  color:#373a36;
  text-align: center;
  font-weight: 700;
  margin-top: 0;
}
.page-header h4{
    color:#373a36;
    font-size: 1.25rem;
    text-align: center;
    line-height: 1.75rem;
    font-weight: 400;
    margin-top: 0;
}
.marketing-sales{
  padding: 5rem 0;
}
.message-solutions{
  a{
    cursor: pointer;
    color:#373a36;
    font-size: 1.5rem;
    text-decoration: none;
    &:hover{
      color:#017dbc;
    }
  }
}
.text-marketing{
  background-color: #f5f5f5;
  h2{
    font-weight: 400;
    margin-bottom: 0;
  }
  p{
    padding: 0 3rem;
  }
}
.v-expansion-panel-header{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.hi-light-box{
  min-height: 230px;
  h5{
    font-size: 1.75rem;
    font-weight: 700;
  }
  p{
    font-size: 1rem;
    font-weight: 400;
  }
  a{
    color: #017dbc;
    text-decoration: none;
    font-weight : 600;
  }
}
.btn-hi-light{
    color:#017dbc;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
}
.getStartedButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .getStarted {
    margin-top: 15px;
    background-color: rgb(233, 178, 0);
    color: rgb(255, 255, 255);
    padding: 16px 52px;
    font-size: 1.5rem;
    font-weight: 400;
    border-radius: 6px;
  }
}
.serviceCardOuterWrapper {
  padding: 15px 36px;
  .serviceCardInnerWrapper {
    height: 400px;
    box-shadow: 0px 0px 100px rgba(0,0,0,0.15);
    border-radius: 6px;
    background-color: white;
    display: flex;
    padding: 30px 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .serviceCardIconImg {
      width: 130px;
      height: 130px;
    }
    .cardHeader {
      margin-top: 35px;
      font-size: 1.3rem;
    }
    .smsCardSubHeader {
      text-align: center;
      font-size: 0.9rem;
      color: grey;
    }
    .cardActionBtn {
      margin-top: 35px;
      cursor: pointer;
      padding: 6px 30px;
      font-size: 0.9rem;
      border: 1px solid rgb(0, 128, 255);
      border-radius: 6px;
      box-shadow: 7px 7px 10px rgba(0,0,0,0.05);
    }
    &.smsService {
      .cardActionBtn {
        border: 1px solid #61BCF5;
        &:hover {
          background-color: #61BCF5;
          color: white;
        }
      }
    }
    &.mmsService {
      .cardActionBtn {
        border: 1px solid #420FB7;
        &:hover {
          background-color: #420FB7;
          color: white;
        }
      }
    }
    &.rmService {
      .cardActionBtn {
        border: 1px solid #FF6A4B;
        &:hover {
          background-color: #FF6A4B;
          color: white;
        }
      }
    }
    &.voiceService {
      .cardActionBtn {
        border: 1px solid #FC6DE5;
        &:hover {
          background-color: #FC6DE5;
          color: white;
        }
      }
    }
  }
}
.pricingWrapper {
  // background-color: rgb(233, 178, 0);
  background-image: url('https://png.pngtree.com/thumb_back/fh260/background/20200714/pngtree-abstract-line-background-design-image_351709.jpg');
  background-position: center;
  background-size: cover;
  height: 300px;
  width: 100vw;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  .pricingIconWrapper {
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: white;
    border-radius: 60px;
    color: rgb(233, 178, 0)!important;
    * {
      color: rgb(233, 178, 0)!important;
    }
    font-size: 2.3rem;
  }
  .pricingHeader {
    font-size: 2rem;
    margin-top: 10px;
    color: white;
  }
  .viewPlansBtn {
    margin-top: 35px;
    cursor: pointer;
    padding: 10px 40px;
    font-size: 0.9rem;
    color: white;
    background-color: rgb(219, 186, 0);
    border-radius: 6px;
    box-shadow: 7px 7px 10px rgba(0,0,0,0.05);
  }
}
.teamMember {
  padding: 1.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
  cursor: pointer;
  img {
    border-radius: 200px;
    width: 180px;
    filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
  &:hover {
    filter: unset; /* IE6-9 */
  -webkit-filter: unset; /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: unset; /* Microsoft Edge and Firefox 35+ */
  }
  }
  .name {
    margin-top: 1.9rem;
    font-size: 1.2rem;
    font-weight: 600;
  }
  .desig {
    font-size: 1rem;
  }
  .summary {
    text-align: center;
    font-size: 0.9rem;

  }
}
.letsconnectTeam {
  width: 100%;
  text-align: center;
  font-size: 2.7rem;
  // margin-bottom: -2rem;
  // margin-top: 4rem;
  font-weight: 400;
}
.text-marketing{
  .round-thumb{
    min-width:8rem;
    height: 8rem;
  }
}
.highlight-industries{
  background-color:#017dbc;
  .round-btn{
    border-radius: 5rem;
    padding: 0.75rem 2rem;
    background-color: #fff;
  }
   .round-thumb{
    min-width:8rem;
    height: 8rem;
  }
}
.faq-section{
  background-color: #f5f5f5;
  padding: 5rem;
  button{

  }
  .v-expansion-panel{
    background-color:transparent !important;
    border-bottom:1px solid #ddd
  }
}

.img-thumb{
  width: auto;
  height: 130px;
}

.round-thumb{
  border-radius: 50%;
  max-width: 5rem;
  min-width: 5rem;
  height: auto;
}

$root-font-size: 16px;

@function fontSize($size) {
  $remSize: $size / $root-font-size;
  @return #{$remSize}rem;
}

/* Font Size */
$fs: 38;
@while $fs > 8 {
  .fs-#{$fs} {
    font-size: $fs * 1px;
  }
  $fs: $fs - 1;
}

//Line-Height
$line-height: 38;
@while $line-height > 8 {
  .line-height-#{$line-height} {
    line-height: $line-height * 1px;
  }
  $line-height: $line-height - 1;
}

/* Font-weight */
$fw: 800;
@while $fw > 400 {
  .fw-#{$fw} {
    font-weight: $fw;
  }
  $fw: $fw - 100;
}

/* Height */
$height: 700;
@while $height > 400 {
  .h-#{$height} {
    height: $height;
  }
  $height: $height - 50;
}

</style>
